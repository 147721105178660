import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "example",
  "type": "project",
  "title": "Arts Club Digital Edition",
  "subtitle": "An on-demand and livestreaming video platform",
  "slug": "/portfolio/digitaledition",
  "author": "Gabriel Kirkley",
  "image": "./digitaledition.jpg",
  "using": "React, NodeJS, PostgreSQL",
  "link": "https://digital.artsclub.com/",
  "sortOrder": 4,
  "published": true,
  "date": "2020-06-28T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`The Idea`}</h3>
    <p>{`In March 2020, the global pandemic due to COVID-19 and subsequent lockdowns sent
the entertainment industry into a spiral. Within days, the company I work for
had to cancel all its shows, and the theatres were empty.`}</p>
    <p>{`From the crisis, I built the `}<strong parentName="p">{`Digital Edition`}</strong>{`.`}</p>
    <h3>{`The Tech`}</h3>
    <p>{`It's a React application on the frontend and a Node/Express application on the
backend with a PostgreSQL database.`}</p>
    <p>{`It features:`}</p>
    <ul>
      <li parentName="ul">{`Full `}<strong parentName="li">{`authentication`}</strong>{` with web tokens and session management`}</li>
      <li parentName="ul">{`Stable `}<strong parentName="li">{`integration with third-party software`}</strong>{` like Tessitura and Zoom`}</li>
      <li parentName="ul"><strong parentName="li">{`Social interactions`}</strong>{` with likes and comments`}</li>
      <li parentName="ul"><strong parentName="li">{`Workshop/event registration`}</strong></li>
    </ul>
    <h3>{`The Best Part`}</h3>
    <p>{`My favourite part of building the site was adding pagination and randomization
for getting videos from the API.`}</p>
    <p>{`When a user is on the site, we wanted to make sure that they wouldn't always see
the same videos in each group. Plus, we had a feature section that displayed
"more videos" to the user, and it would be kinda boring if the same two videos
were always listed there. A machine learning model for recommending videos was
outside the scope of this project (and unnecessary), so I needed to be able to
retrieve data from the database at random and allow for pagination.`}</p>
    <p>{`I knew that a potential solution was to order the videos by their ID `}<strong parentName="p">{`and`}</strong>{` a
modulo of the ID. In other words, retrieving truly random results isn't
necessary - just a shuffled, repeatable order.`}</p>
    <p>{`Initially, I had it set up to use a single modulus (7), but it quickly became
apparent that this presented the same problem: shuffled results, sure, but they
were always the same! Getting a genuinely random number as the modulus was
vital. Here is what I added on the backend:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`query.orderByRaw(\`video.id % \${randomizer}, video.id\`);
`}</code></pre>
    <p>{`Now, the user got random videos, but duplicated again and again! The order
fetched from the database needed to be repeatable to prevent duplicates. So I
knew that this was a job for some good old React state.`}</p>
    <p>{`In the final solution on the frontend, I get a random number between 2 and 11
and store it in state. Here's what that looks like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const [randomizer] = useState(Math.floor(Math.random() * 11) + 2);
`}</code></pre>
    <p>{`This way, on the next fetch to the database, there is a repeatable order to the
returned results and no duplicates on the frontend!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      